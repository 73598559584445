import { post } from '../request';
import { Iresponse } from '../interface';

export const forumAdd = (params?: any): Promise<Iresponse> => {
  return post({ url: '/member/i/web/bss/add', params });
};
// 获取文章列表
export const GetArticleList = (params?: any): Promise<Iresponse> => {
  return post({ url: '/member/i/web/cms/page', params });
};
// 获取文章列表
export const GetArticleDetail = (params?: any): Promise<Iresponse> => {
  return post({ url: '/member/i/web/cms/get', params });
};
// 投稿
export const CmsArticleAdd = (params?: any): Promise<Iresponse> => {
  return post({ url: '/member/i/web/cms/user/add', params });
};
// 投稿记录列表
export const GetCmsArticleList = (params?: any): Promise<Iresponse> => {
  return post({ url: '/member/i/web/cms/user/page', params });
};
// 获取投稿详情
export const GetCmsArticleDeteil = (params?: any): Promise<Iresponse> => {
  return post({ url: '/member/i/web/cms/user/get', params });
};
// 获取会员服务首页七个板块（每个板块5条） + 全站搜索
export const GetServeList = (params?: any): Promise<Iresponse> => {
  return post({ url: '/member/i/web/cms/list', params });
};

/**
 * 消息模块接口
 */
// 获取消息列表
export const GetMessageList = (params?: any): Promise<Iresponse> => {
  return post({ url: '/member/i/web/notification/page', params });
};
// 获取消息列表
export const GetMessageDetail = (params?: any): Promise<Iresponse> => {
  return post({ url: '/member/i/web/notification/get', params });
};

// 入会弹框
export const prompttag = (params?: any): Promise<Iresponse> => {
  return post({ url: '/member/i/web/member/info/prompt/tag', params });
};

// 更新弹框入会
export const upprompttag = (params?: any): Promise<Iresponse> => {
  return post({ url: '/member/i/web/member/info/up/prompt/tag', params });
};


