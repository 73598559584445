











































































































import { Component, Vue } from 'vue-property-decorator';
import { GetMessageList } from '@/http/api/ArticleApi';


@Component
export default class Message extends Vue {
  private loading: boolean = false;
  private isShow: boolean = false;
  private totalPage: number = 0; // 总页数
  private totalCount: number = 0; // 总条数
  private pageNo: number = 1; // 当前页数
  private pageSize: number = 10; // 每页几条
  private tableData: any[] = [];
  private created() {
    this.getList();
  }
  // 跳转消息详情
  private toDeteil(id: string): void {
    this.$router.push({
      path: 'message/detail',
      query: {
        id,
      },
    });
  }
  // 获取消息列表
  private getList(): void {
    this.loading = true;
    GetMessageList({
      pageNo: this.pageNo,
      pageSize: this.pageSize,
    }).then((res) => {
      this.loading = false;
      if (res.code === '000') {
        const data = res.result;
        if (data.total > 0) {
          this.isShow = true;
        }
        this.totalCount = data.total;
        this.totalPage = data.pages;
        this.pageNo = data.current;
        this.tableData = data.records;
      }
    });
  }
  private handleSizeChange(val: number): void {
    this.pageNo = 1;
    this.pageSize = val;
    this.getList();
  }
  private handleCurrentChange(val: number): void {
    this.pageNo = val;
    this.getList();
  }
}
